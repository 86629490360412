import resilience from "./assets/resilience.png";
import meaningfulLife from "./assets/meaningful-life.png";
import accomplished from "./assets/accomplished.png";
import connected from "./assets/connected.png";
import active from "./assets/active.png";

export type OptionType = {
  label: string;
  value: number;
};

export type StepId =
  | "mentallyResilient"
  | "meaningfulLife"
  | "accomplishedInLife"
  | "connection"
  | "activeAndEngaged";

export type StepDataType = {
  id: StepId;
  question: string;
  options: OptionType[];
  image: any;
  explainQuestionText: string;
  explainBody: () => any;
};

const options: OptionType[] = [
  {
    label: "Not very",
    value: 0,
  },
  {
    label: "A little bit",
    value: 1,
  },
  {
    label: "Moderately",
    value: 2,
  },
  {
    label: "Highly",
    value: 3,
  },
];

const stepsData: StepDataType[] = [
  {
    id: "mentallyResilient",
    question: "How mentally resilient are you?",
    options,
    explainQuestionText: "Explain mental resilience",
    explainBody: () => (
      <p className="text-center">
        Possessing a variety of coping mechanisms for facing life challenges.
        This includes a great support network and personal tools for wellbeing
        such as meditation and time management. 🧘🏽
      </p>
    ),
    image: resilience,
  },
  {
    id: "meaningfulLife",
    question: "How meaningful is your life?",
    options,
    explainQuestionText: "Explain meaningful",
    explainBody: () => (
      <p className="text-center">
        Feeling a sense of belonging and purpose from contributing to something
        larger than yourself, such as nature, social groups, organizations,
        movements, values, or spiritual traditions. 🏘️
      </p>
    ),
    image: meaningfulLife,
  },
  {
    id: "accomplishedInLife",
    question: "How accomplished are you in your life?",
    options,
    explainQuestionText: "Explain accomplishment",
    explainBody: () => (
      <p className="text-center">
        A feeling of contentment that comes from pursuing success and mastery as
        an individual or with others, even when this presents emotional and
        practical challenges. ✅
      </p>
    ),
    image: accomplished,
  },
  {
    id: "connection",
    question: "How connected do you feel to the people in your life?",
    options,
    explainQuestionText: "Explain connection",
    explainBody: () => (
      <p className="text-center">
        Receiving and giving positivity and support through relationships. It’s
        important to have close relationships and be part of larger communities
        to experience the full benefits. 🤗
      </p>
    ),
    image: connected,
  },
  {
    id: "activeAndEngaged",
    question: "How engaged are you in your life?",
    options,
    explainQuestionText: "Explain engaged",
    explainBody: () => (
      <p className="text-center">
        This is when you become completely absorbed in an activity to the point
        where you experience effortless involvement. This is known as “flow” and
        leads to a sense of ecstasy and clarity. 🤔
      </p>
    ),
    image: active,
  },
];

export default stepsData;
