import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "../Input";

import { IWellbeingQuiz, WizardFormContext } from "../../App";
import { IUseWizard } from "../../hooks/useWizard";
import { getAmplitudeInstance } from "../../analytics";

const schema = yup.object({
  cohort: yup.string(),
  email: yup
    .string()
    .email("Please enter a valid email")
    .when("cohort", {
      is: (val: string) => val !== "firststeps",
      then: yup
        .string()
        .email("Please enter a valid email")
        .required("Required"),
    }),
});

type EmailStepForm = {
  email: string;
  cohort: string;
};

const EmailStep = () => {
  const { onNext, data, updateData } =
    React.useContext<IUseWizard<IWellbeingQuiz>>(WizardFormContext);
  const { register, handleSubmit, formState } = useForm<EmailStepForm>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: data.email ?? undefined,
      cohort: data.cohort,
    },
  });

  const { errors, isValid, isSubmitting } = formState;

  const onSubmit = React.useCallback(
    async (formData: EmailStepForm) => {
      getAmplitudeInstance().setUserProperties({
        email: formData.email,
      });
      getAmplitudeInstance().logEvent("chosen-email");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/quiz`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          email: formData.email,
        }),
      });
      if (res.ok) {
        getAmplitudeInstance().logEvent("saved-quiz", {
          ...data,
        });
      } else {
        getAmplitudeInstance().logEvent("failed-save-quiz", {
          ...data,
        });
      }
      updateData(formData);
      onNext();
    },
    [updateData, onNext, data]
  );

  React.useEffect(() => {
    getAmplitudeInstance().logEvent("view-email-step");
  }, []);

  return (
    <>
      <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-center text-3xl text-darkBlue mb-4">
          Unlock your results
        </h1>
        <p className="text-center mb-2">
          ✉️ We&apos;ll email you a copy of your results &amp; recommendations.
        </p>
        <div className="mb-6">
          <div className="mt-1 mb-3">
            <Input
              {...register("email")}
              type="email"
              name="email"
              id="email"
              placeholder="Your email"
              errors={errors.email}
            />
          </div>
          <p className="text-center mb-1 text-sm">
            We do not share your individual results with anyone. They are for
            your eyes only.
          </p>
        </div>
        <button
          type="submit"
          disabled={!isValid || isSubmitting}
          className={`transition-all block w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light ${
            isValid ? "" : "bg-gray-300 hover:bg-gray-300 cursor-not-allowed"
          }`}
        >
          {isSubmitting ? "Loading your results..." : "See my results"}
        </button>
      </form>
    </>
  );
};

export default EmailStep;
