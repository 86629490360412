import { Disclosure } from "@headlessui/react";

type AboutProps = {
  cohortId?: string;
};

const About: React.FC<AboutProps> = () => {
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full text-center mb-2 py-2 text-left font-medium text-blue focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
              <p>About Clearhead</p>
            </Disclosure.Button>
            <Disclosure.Panel className="pb-2 text-sm text-center">
              Clearhead exists to make finding mental wellbeing support easy by
              connecting you to the help you need, when you need it. We provide
              our suite of self-help tools, with a personalised selection
              recommended in the results of this quiz.
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default About;
