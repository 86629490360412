export interface ITool {
  name?: string;
  image?: string;
  color?: string;
  description?: string;
  invert?: boolean;
}

export const ToolsComponentList = {
  ActiveListening: {
    name: 'Active Listening',
    color: '#9DDBCB',
    description: 'A listening exercise designed to improve your ability to listen.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/active-listening-intro.svg',
  },
  DeepBreathing: {
    name: 'Deep Breathing',
    color: '#E4EDF8',
    description: 'A guided breathing exercise to calm yourself down when you need it.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/deep-breathing-intro.svg',
  },
  MySignals: {
    name: 'My Signals',
    color: '#F2F9E2',
    description: 'Learn your signs of emotion and practice guided breathing to calm down.',
    image: 'https://storage.googleapis.com/clearhead.appspot.com/tool-images/my-signals-intro.svg',
  },
  FlippingThoughts: {
    name: 'Flipping Thoughts',
    color: '#EDEAF4',
    description: "Learn to flip negative thoughts into perspectives you'll find useful",
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/flipping-thoughts-intro.svg',
  },
  MindfulWatching: {
    name: 'Mindful Watching',
    color: '#E4EDF8',
    description: 'A relaxing mindfulness exercise designed to improve your sense of sight.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/mindful-watching-intro.svg',
  },
  MindfulListening: {
    name: 'Mindful Listening',
    color: '#FFECA4',
    description: 'A calming soundscape experience to improve your sense of hearing.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/mindful-listening-intro.svg',
  },
  MindfulFeeling: {
    name: 'Mindful Feeling',
    color: '#F0E4FF',
    description: 'A mindfulness exercise designed to connect you with feelings in your body.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/mindful-feeling-intro.svg',
  },
  MindfulThinking: {
    name: 'Mindful Thinking',
    color: '#FFFBF7',
    description: 'A mindfulnees exercise to help you better observe your thoughts.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/useful-thinking-intro.svg',
  },
  MuscleRelaxation: {
    name: 'Muscle Relaxation',
    color: '#FFE4CE',
    description: 'Guided muscle relaxation to ease tension and feel better.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/muscle-relaxation-intro.svg',
  },
  FullBodyRelaxation: {
    name: 'Full Body Relaxation',
    color: '#CAF0E6',
    description: 'Guided body scanning to release muscle tension and feel better.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/full-body-relaxation-intro.svg',
  },
  GratitudePractice: {
    name: 'Gratitude Practice',
    color: '#FFE2CD',
    description: 'A guided exercise to connect with the good feels from life gifts.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/gratitude-practice-intro.svg',
  },
  MoodJournal: {
    name: 'Mood Journal',
    color: '#9DDBCB',
    description: 'Discover the connections between what you do and how you feel.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/mood-journal-intro.svg',
  },
  SleepHarmony: {
    name: 'Sleep Harmony',
    color: '#343896',
    description: 'Improve sleep by easily finding the right tip and applying it to your life.',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/sleep-harmony-intro.svg',
    invert: true,
  },
  FindATherapist: {
    name: 'Find A Therapist',
    color: '#E4EDF8',
    description: '',
    image:
      'https://storage.googleapis.com/clearhead.appspot.com/tool-images/find-a-therapist-intro.svg',
  },
  ThoughtDetective: {
    name: 'Thought Detective',
    color: '#E4EDF8',
    description: '',
    image: 'https://storage.googleapis.com/clearhead.appspot.com/tool-images/thought-detective-intro.svg',
  },
};

const getToolComponentForName = (name: string | undefined): ITool | null => {
  const toolName = name?.toLowerCase();
  switch (toolName) {
    case 'active-listening':
      return ToolsComponentList['ActiveListening'];
    case 'deep-breathing':
      return ToolsComponentList['DeepBreathing'];
    case 'my-anger-signals':
    case 'my-anxiety-signals':
    case 'my-low-mood-signals':
    case 'my-stress-signals':
    case 'my-signals':
      return ToolsComponentList['MySignals'];
    case 'recognising-thinking-distortions':
    case 'flipping-thoughts':
      return ToolsComponentList['FlippingThoughts'];
    case 'mindful-watching':
      return ToolsComponentList['MindfulWatching'];
    case 'mindful-listening':
      return ToolsComponentList['MindfulListening'];
    case 'mindful-feeling':
      return ToolsComponentList['MindfulFeeling'];
    case 'mindful-thinking':
      return ToolsComponentList['MindfulThinking'];
    case 'muscle-relaxation':
      return ToolsComponentList['MuscleRelaxation'];
    case 'full-body-muscle-relaxation':
    case 'full-body-relaxation':
      return ToolsComponentList['FullBodyRelaxation'];
    case 'gratitude-practice':
      return ToolsComponentList['GratitudePractice'];
    case 'mood-journal':
    case 'mood-tracker':
      return ToolsComponentList['MoodJournal'];
    case 'sleep-harmony':
      return ToolsComponentList['SleepHarmony'];
    case 'find-a-therapist':
      return ToolsComponentList['FindATherapist'];
    case 'thought-detective':
      return ToolsComponentList['ThoughtDetective'];
    default:
      return null;
  }
};

export default getToolComponentForName;
