import * as React from "react";
import { Disclosure, Transition, RadioGroup } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/outline";

import { getAmplitudeInstance } from "../../analytics";
import { IWellbeingQuiz, WizardFormContext } from "../../App";
import { IUseWizard } from "../../hooks/useWizard";
import { StepDataType } from "../../stepData";

const QuestionStep: React.FC<StepDataType> = ({
  id,
  question,
  options,
  explainBody,
  explainQuestionText,
  image,
}) => {
  const { onNext, onPrevious, data, updateData } =
    React.useContext<IUseWizard<IWellbeingQuiz>>(WizardFormContext);
  const [selectedOption, setSelectedOption] = React.useState<
    number | undefined
  >(data[id] ?? undefined);

  const onSubmit = React.useCallback(async () => {
    if (selectedOption !== undefined) {
      getAmplitudeInstance().logEvent(`chosen-${id}`, {
        value: selectedOption,
      });
      updateData({
        [id]: selectedOption,
      });

      onNext();
    }
  }, [id, selectedOption, onNext, updateData]);

  const isDisabled = selectedOption === undefined;

  React.useEffect(() => {
    getAmplitudeInstance().logEvent(`view-${id}-step`);
    window.scrollTo(0, 0);
  });

  return (
    <div className="px-4">
      <img src={image} alt={question} className="h-36 w-auto mx-auto mb-3" />
      <h1 className="text-center text-3xl text-darkBlue mb-3">{question}</h1>
      {data.cohort === "firststeps" ? (
        <p className="text-center mt-3 mb-4 bg-yellow-100 rounded-md">
          If you need support now: Free call or text{" "}
          <a href="tel:1737" className="text-blue">
            1737
          </a>
        </p>
      ) : null}
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full text-center mb-3">
              <span className="text-blue">
                {explainQuestionText}
                <ChevronDownIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } h-4 w-4 ml-2 inline`}
                />
              </span>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="mb-4">
                {explainBody}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <div className="mb-6">
        <RadioGroup
          value={selectedOption}
          onChange={(option) => setSelectedOption(option)}
        >
          <div className="space-y-2">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.label}
                value={option.value}
                className={({ checked }) =>
                  `${checked ? "bg-green text-white" : "bg-white"}
                    relative rounded-lg px-5 py-4 cursor-pointer flex focus:outline-none border border-blue-lightest`
                }
              >
                {({ checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="h3"
                            className={`${
                              checked ? "text-white" : "text-darkBlue"
                            } text-left`}
                          >
                            {option.label}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-white">
                          <CheckIcon className="w-5 h-5" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      <div className="bg-white/50 flex justify-between items-center sticky bottom-0 pt-2 pb-2">
        <button
          type="button"
          onClick={async () => onPrevious()}
          className={`block mr-2 w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-blue rounded-full text-blue bg-white hover:bg-blue-light hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light`}
        >
          Back
        </button>
        <button
          onClick={onSubmit}
          disabled={isDisabled}
          className={`transition-colors block ml-2 w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light ${
            isDisabled ? "bg-gray-300 cursor-not-allowed hover:bg-gray-300" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QuestionStep;
