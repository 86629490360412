import * as React from "react";

export interface IUseWizard<T> {
  data: T;
  updateData: (partialData: T) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  onNext: (skip?: number) => Promise<void>;
  onPrevious: (skip?: number) => Promise<void>;
  isFirstStep: () => boolean;
  isLastStep: () => boolean;
  clearData: () => void;
}

const useWizard = <T>(
  initialData: T,
  steps: number,
  initialStep: number
): IUseWizard<T> => {
  const [data, setData] = React.useState<T>(initialData);
  const [currentStep, setCurrentStep] = React.useState<number>(
    initialStep || 0
  );

  const updateData = React.useCallback(
    (partialData: T) => {
      setData((prevState) => ({
        ...prevState,
        ...partialData,
      }));
    },
    [setData]
  );

  const clearData = () => setData(initialData);

  const isLastStep = React.useCallback(() => {
    return currentStep === steps - 1;
  }, [currentStep, steps]);

  const isFirstStep = React.useCallback(() => {
    return currentStep === 0;
  }, [currentStep]);

  const onNext = React.useCallback(
    async (skip?: number) => {
      const nextPages = skip !== undefined && skip !== 0 ? skip + 1 : 1;
      if (currentStep < steps) {
        setCurrentStep(currentStep + nextPages);
      }
    },
    [currentStep, steps]
  );

  const onPrevious = React.useCallback(
    async (skip?: number) => {
      const prevPages = skip ? skip + 1 : 1;
      if (currentStep > 0) {
        setCurrentStep(currentStep - prevPages);
      }
    },
    [currentStep]
  );

  return {
    data,
    updateData,
    currentStep,
    setCurrentStep,
    onNext,
    onPrevious,
    isFirstStep,
    isLastStep,
    clearData,
  };
};

export function createWizardFormContext<T>(): React.Context<IUseWizard<T>> {
  return React.createContext<IUseWizard<T>>({
    data: {} as T,
    updateData: (partialData: T) => {
      console.log("implement updateData: ", partialData);
    },
    currentStep: 0,
    onNext: async () => {
      console.log("implement the onNext");
    },
    onPrevious: async () => {
      console.log("implement the onPrevious");
    },
    setCurrentStep: (step: number) => {
      console.log("implementation on setCurrentStep", step);
    },
    isFirstStep: () => false,
    isLastStep: () => false,
    clearData: () => {
      console.log("implementation on clearData");
    },
  });
}

export default useWizard;
