import amplitude from "amplitude-js";

export const initAmplitude = () => {
  if (typeof window != "undefined") {
    amplitude
      .getInstance("clearhead")
      .init(`${process.env.REACT_APP_AMPLITUDE_KEY}`, undefined, {
        includeUtm: true,
        includeReferrer: true,
      });
  }
};

export const getAmplitudeInstance = () => {
  const instance = amplitude.getInstance("clearhead");
  return instance;
};
