import * as React from "react";
import { getAmplitudeInstance } from "../../analytics";
import { IToolData, IWellbeingQuiz, WizardFormContext } from "../../App";
import { IUseWizard } from "../../hooks/useWizard";
import { getMappedKey, getWeakness } from "../../results";

interface IResponseBody {
  tool1?: IToolData;
  tool2?: IToolData;
  tool3?: IToolData;
  topic?: string[];
}

const ChallengesStep = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { onNext, onPrevious, data, updateData } =
    React.useContext<IUseWizard<IWellbeingQuiz>>(WizardFormContext);
  const [challenges, setChallenges] = React.useState<string>("");

  const onChange = (e: any) => {
    setChallenges(e.target.value);
  };

  const onSubmit = React.useCallback(async () => {
    setLoading(true);
    updateData({ challenges });
    const paramArr: string[] = [];
    if (challenges.length > 0) {
      paramArr.push(`context=${challenges}`);
    }
    const weakness = getWeakness(data);
    if (weakness) {
      paramArr.push(`weakness=${getMappedKey(weakness)}`);
    }
    const url = `${process.env.REACT_APP_TOOL_API}/parse?${paramArr.join("&")}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const bodyData = (await response.json()) as IResponseBody;
      const tools: IToolData[] = [];

      if (bodyData.tool1) {
        tools.push(bodyData.tool1);
      }
      if (bodyData.tool2) {
        tools.push(bodyData.tool2);
      }
      if (bodyData.tool3) {
        tools.push(bodyData.tool3);
      }

      updateData({ topic: bodyData.topic, toolData: tools });
      getAmplitudeInstance().logEvent("fetched-tools", {
        ...data,
        topic: bodyData.topic,
        challenges,
      });
    } else {
      getAmplitudeInstance().logEvent("failed-fetched-tools", {
        ...data,
      });
    }

    setLoading(false);
    onNext();
  }, [challenges, data, onNext, updateData]);

  React.useEffect(() => {
    getAmplitudeInstance().logEvent(`view-challenges-step`);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="px-4">
      <h1 className="text-center text-3xl text-darkBlue mb-3">Challenges</h1>
      {data.cohort === "firststeps" ? (
        <p className="text-center mt-3 mb-4 bg-yellow-100 rounded-md">
          If you need support now: Free call or text{" "}
          <a href="tel:1737" className="text-blue">
            1737
          </a>
        </p>
      ) : null}
      <div className="text-center">
        <div className="mb-10">
          We would like to recommend tools that are uniquely suited to helping
          you with your personal challenges. Answering the following question
          will help us match you with the right tools.
        </div>
        <h3 className="text-darkBlue mb-4">
          What challenges are you facing in life?
        </h3>
        <textarea
          maxLength={255}
          value={challenges}
          onChange={onChange}
          placeholder="E.g. I'm struggling with high workload..."
          rows={4}
          name="challenges"
          className="mb-6 shadow-sm focus:ring-blue focus:border-blue block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="bg-white/50 flex justify-between items-center sticky bottom-0 pt-2 pb-2">
        <button
          type="button"
          onClick={async () => onPrevious()}
          disabled={loading}
          className={`block mr-2 w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-blue rounded-full text-blue bg-white hover:bg-blue-light hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light ${
            loading
              ? "hover:bg-white hover:text-blue opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          Back
        </button>
        <button
          onClick={onSubmit}
          disabled={loading}
          className={`transition-colors block ml-2 w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light ${
            loading ? "bg-gray-300 cursor-not-allowed hover:bg-gray-300" : ""
          }`}
        >
          {loading ? "Loading..." : "Next"}
        </button>
      </div>
    </div>
  );
};

export default ChallengesStep;
