import * as React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { FieldError } from "react-hook-form";

type InputProps = {
  id: string;
  name: string;
  value?: string;
  errors?: FieldError;
  placeholder?: string;
  label?: string;
  type?: string;
  pattern?: string;
  autoComplete?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      placeholder,
      errors,
      className,
      type = "text",
      value,
      ...rest
    },
    ref
  ) => {
    const hasError = !!(errors && errors.message && errors.message?.length);
    return (
      <div>
        {label ? (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        ) : null}
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            ref={ref}
            type={type}
            name={name}
            id={id}
            className={`block max-w-lg w-full shadow-sm focus:ring-blue-light focus:border-blue-light sm:text-sm border-gray-300 rounded-md ${
              hasError
                ? "border-red text-red placeholder-red focus:ring-red focus:border-red"
                : ""
            } ${className}`}
            placeholder={placeholder}
            aria-invalid="true"
            aria-describedby={name}
            value={value}
            {...rest}
          />
          {hasError ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {hasError ? (
          <p className="mt-2 text-sm text-red" id={name}>
            {errors?.message}
          </p>
        ) : null}
      </div>
    );
  }
);

export default Input;
