import * as React from "react";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import useWizard, { createWizardFormContext } from "./hooks/useWizard";
import stepsData from "./stepData";
import { getAmplitudeInstance } from "./analytics";

import GeneralIntroStep from "./components/GeneralIntroStep";
import EmailStep from "./components/EmailStep";
import QuestionStep from "./components/QuestionStep";
import ResultStep from "./components/ResultStep";
import ChallengesStep from "./components/ChallengesStep";

import completedProgress from "./assets/completed.svg";
import logo from "./logo.png";

let steps: JSX.Element[] = [];
const questionSteps = stepsData.map((x) => <QuestionStep key={x.id} {...x} />);
const emailStep = <EmailStep />;
steps = [
  <GeneralIntroStep />,
  ...questionSteps,
  <ChallengesStep />,
  emailStep,
  <ResultStep />,
];

export interface IToolData {
  name: string;
  title: string;
  script: string;
}

export interface IWellbeingQuiz {
  cohort?: string;
  email?: string;
  workPlaceIssues?: Record<string, boolean>;
  workPlaceIssuesOther?: string;
  mentallyResilient?: number;
  meaningfulLife?: number;
  accomplishedInLife?: number;
  connection?: number;
  activeAndEngaged?: number;
  challenges?: string;
  toolData?: IToolData[];
  topic?: string[];
}

export const WizardFormContext = createWizardFormContext<IWellbeingQuiz>();

const initialData = {};

const App = () => {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const { cohortId } = useParams();

  const value = useWizard<IWellbeingQuiz>(
    {
      ...initialData,
      cohort: cohortId,
    },
    steps.length,
    0
  );

  const renderStep = React.useCallback(() => {
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={value.currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {steps[value.currentStep]}
        </motion.div>
      </AnimatePresence>
    );
  }, [value.currentStep]);

  const completed = value.currentStep === steps.length - 1;

  React.useEffect(() => {
    getAmplitudeInstance().setUserProperties({
      tenant: cohortId,
    });
    if (cohortId === "firststeps") {
      steps = steps.filter(function(value){ 
        return value !== emailStep;
    });

    }
    getAmplitudeInstance().logEvent("view-quiz");
    setInitialized(true);
  }, [cohortId]);



  const baseUrl = React.useMemo(() => {
    let clearheadDomain = "myclearhead.com" 
    if (typeof window != "undefined") {
      if(window.location.host.startsWith('quiz.clearhead.org.nz')){
        clearheadDomain = "clearhead.org.nz"
      }
    }


    if (cohortId && cohortId.length > 0) {
      return `https://${cohortId}.${clearheadDomain}`;
    } else {
      return `https://www.${clearheadDomain}`;
    }
  }, [cohortId]);

  return (
    <div className="flex flex-col h-screen">
      <main>
        <WizardFormContext.Provider value={value}>
          {initialized ? (
            <div className="w-full mx-auto py-4 px-3 lg:px-6 sm:max-w-md">
              <div className="relative w-full bg-gray-200 rounded-full h-3 dark:bg-blue-lightest mb-12">
                <div
                  className={`bg-blue h-3 rounded-full transition-all ${
                    completed ? "bg-green" : ""
                  }`}
                  style={{
                    width: `${(value.currentStep / (steps.length - 1)) * 100}%`,
                  }}
                />
                {completed ? (
                  <img
                    src={completedProgress}
                    alt="Completed wellbeing quiz"
                    className="absolute -top-3 -right-3 h-14 w-14"
                  />
                ) : null}
              </div>
              {renderStep()}
            </div>
          ) : null}
        </WizardFormContext.Provider>
      </main>
      <div className="bg-blue-lightest mt-2">
        <div className="w-full mx-auto py-6 px-3 lg:px-6 md:max-w-xl">
          <div className="flex flex-col justify-center items-center">
            <p className="mb-2">© 2022 Clearhead. All rights reserved. </p>
            <p className="mb-2">Built with ❤️ in New Zealand.</p>
            <div className="flex text-center mb-3">
              <a
                className="text-blue"
                target={"_blank"}
                href={`${baseUrl}/terms-of-use`}
                rel="noreferrer"
              >
                Terms of use
              </a>{" "}
              <span className="mx-2">|</span>
              <a
                className="text-blue"
                target={"_blank"}
                href={`${baseUrl}/privacy-policy`}
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
            <img className="h-8" src={logo} alt="Clearhead Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
