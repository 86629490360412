import { ArrowLeftIcon } from "@heroicons/react/solid";
import { getAmplitudeInstance } from "../../analytics";
import { IToolData } from "../../App";
import { ITool } from "./toolList";

interface IToolCard {
  toolData: IToolData;
  tool: ITool;
  baseUrl: string;
  referrer?: string;
}

const ToolCard: React.FC<IToolCard> = ({
  toolData,
  tool,
  baseUrl,
  referrer,
}) => {
  const onToolClick = () => {
    getAmplitudeInstance().logEvent("quiz-click-tool", {
      cohort: referrer,
      tool: tool.name,
    });
    let targetUrl = `${baseUrl}/app/tools/${toolData.title}?utm_campaign=onboarding-quiz`;
    if (referrer && referrer.length > 0) {
      targetUrl += `&utm_source=${referrer}`;
    }
    window.open(targetUrl, "_blank");
  };

  return (
    <div
      className={`${
        tool.invert ? "text-white" : "text-darkBlue"
      } rounded-md flex p-3 pr-2 mb-6`}
      style={{ backgroundColor: tool.color }}
    >
      <div className="flex flex-col justify-between">
        <div>
          <h5 className="mb-3">{tool.name}</h5>
          <p className="mb-3 text-sm">
            {tool.description ? tool.description : toolData.script}
          </p>
        </div>
        <div>
          <button
            className="font-semibold flex items-center"
            onClick={onToolClick}
          >
            <p className="mr-2 text-sm">Try this tool</p>
            <ArrowLeftIcon className="rotate-180 w-4 h-4 relative top-px" />
          </button>
        </div>
      </div>
      <img src={tool.image} alt={tool.name} className="w-20 h-20" />
    </div>
  );
};

export default ToolCard;
