import * as React from "react";
import Lottie from "react-lottie";

import * as animationData from "../../assets/plants/flourish.json";

import { IWellbeingQuiz, WizardFormContext } from "../../App";
import { IUseWizard } from "../../hooks/useWizard";
import { getAmplitudeInstance } from "../../analytics";
import About from "../About";

const GeneralIntroStep = () => {
  const { onNext, data } =
    React.useContext<IUseWizard<IWellbeingQuiz>>(WizardFormContext);

  const onSubmit = React.useCallback(() => {
    getAmplitudeInstance().logEvent("completed-general-intro-step");
    onNext();
  }, [onNext]);

  React.useEffect(() => {
    getAmplitudeInstance().logEvent("view-general-intro-step");
  }, []);

  return (
    <>
      <div className="px-4">
        <h1 className="text-center text-3xl text-darkBlue mb-3">
          How healthy is my wellbeing?
        </h1>
        {data.cohort === "firststeps" ? (
          <p className="text-center mt-3 mb-4 bg-yellow-100 rounded-md">
            If you need support now: Free call or text{" "}
            <a href="tel:1737" className="text-blue">
              1737
            </a>
          </p>
        ) : null}
        <p className="text-center mb-4">
          Answer five questions to reveal your wellbeing level and how you can
          improve it. It will only take 2-3 minutes.
        </p>
        <Lottie
          options={{ animationData, loop: false, autoplay: true }}
          height={200}
          width={200}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => console.log("the animation completed:"),
            },
          ]}
        />

        <button
          type="button"
          onClick={() => onSubmit()}
          className={`transition-all mt-4 block w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light`}
        >
          Start Quiz
        </button>

        <div className="mt-3">
          <About cohortId={data.cohort} />
        </div>
      </div>
    </>
  );
};

export default GeneralIntroStep;
