import { getAmplitudeInstance } from "./analytics";
import flourishing from "./assets/windy/flourishing.json";
import good from "./assets/windy/good.json";
import ok from "./assets/windy/ok.json";
import deflated from "./assets/windy/deflated.json";
import { StepId } from "./stepData";
import { IWellbeingQuiz } from "./App";

type Result = {
  image: any;
  title: string;
  body: any;
  improve: any;
  buttonText: any;
  buttonUrl: any;
};

const buttonUrl = (baseUrl: string, referrer: string): void => {
  getAmplitudeInstance().logEvent("post-quiz-signup", { cohort: referrer });
  let targetUrl = `${baseUrl}/auth?type=signup&utm_campaign=onboarding-quiz`;
  if (referrer && referrer.length > 0) {
    targetUrl += `&utm_source=${referrer}`;
  }
  window.open(targetUrl, "_blank");
};

const deflatedResult: Result = {
  image: deflated,
  title: "You may need a hand",
  body: () => (
    <>
      <p>
        Your wellbeing appears to be depleted. You may be experiencing
        challenging life events, stressors, and negative thought patterns.
      </p>
      <br />
      <p>
        It&apos;s important to remember that it&apos;s not your fault; everyone
        needs care at times.
      </p>
    </>
  ),
  improve: (
    baseUrl: string,
    eligibleSessions: number = 3,
    referrer: string
  ) => (
    <>
      <p>
        You may be vulnerable to burn out and developing mental health issues.
        It&apos;s time to seek support from people you trust and mental health
        professionals.
      </p>
      <br />
      <p>
        Try Clearhead&apos;s{" "}
        <a className="text-blue" href={`${baseUrl}/find-a-therapist`}>
          find a therapist
        </a>{" "}
        search engine.
      </p>
      <p>You are eligible for {eligibleSessions} free sessions.</p>
    </>
  ),
  buttonText: "Try out the app",
  buttonUrl,
};

const okResult: Result = {
  image: ok,
  title: "You have an ok level of wellbeing",
  body: () => (
    <>
      <p>
        This is also known as languishing and is really common. There&apos;s
        lots of room for improvement; you&apos;re essentially getting on with
        life, but not living to your full potential.
      </p>
    </>
  ),
  improve: (baseUrl: string) => (
    <>
      <p>
        Seek advice from your support network, consider therapy for personal
        development, and begin building up your toolkit for mental wellbeing.
      </p>
      <br />
      <p>
        Try Clearhead&apos;s digital therapy app where you can find the right
        tools for you guided by our intelligent chatbot
      </p>
    </>
  ),
  buttonText: "Try out the app",
  buttonUrl,
};

const healthyResult: Result = {
  image: good,
  title: "You have a healthy level of wellbeing",
  body: () => (
    <>
      <p>
        Nice one, a healthy state of wellbeing is a great place to be! It means
        you&apos;re living your life in a way that&apos;s good for your mind and
        body. That said, it could still be better.
      </p>
    </>
  ),
  improve: (baseUrl: string) => (
    <>
      <p>
        We recommend developing further mental resilience, self-awareness, and
        adding more personal tools to your resilience skillset. This will help
        you feel better and cope with any challenges that come up.
      </p>
      <br />
      <p>
        Clearhead&apos;s digital therapy app takes you on a journey of personal
        development through interactive exercises guided by our friendly
        chatbot.
      </p>
    </>
  ),
  buttonText: "Try out the app",
  buttonUrl,
};

const flourishingResult: Result = {
  image: flourishing,
  title: "You're flourishing",
  body: () => (
    <>
      <p>
        Congratulations, this is the highest state of wellbeing! It&apos;s
        generally accompanied by feelings of happiness, fulfillment, and
        satisfaction, so we imagine you&apos;re feeling great.
      </p>
    </>
  ),
  improve: (baseUrl: string) => (
    <>
      <p>
        It&apos;s important to enjoy the view while you&apos;re at the top, but
        don&apos;t get slack on personal development. Keep growing your personal
        toolkit and strengthening your resilience.
      </p>
      <br />
      <p>
        Clearhead&apos;s digital therapy app has a range of tools for personal
        development. Our chatbot will recommend the right ones for you, or you
        can master each tool one-by-one in the journey.
      </p>
    </>
  ),
  buttonText: "Try out the app",
  buttonUrl,
};

export const keys: StepId[] = [
  "mentallyResilient",
  "meaningfulLife",
  "accomplishedInLife",
  "connection",
  "activeAndEngaged",
];

export const getMappedKey = (key: StepId) => {
  switch (key) {
    case "mentallyResilient":
      return "Mental resilience";
    case "meaningfulLife":
      return "Meaningfulness";
    case "accomplishedInLife":
      return "Accomplishment";
    case "connection":
      return "Connection";
    case "activeAndEngaged":
      return "Engagement";
  }
};

export const calcResult = (totalNum: number) => {
  if (totalNum <= 3) {
    return deflatedResult;
  } else if (totalNum > 3 && totalNum <= 7) {
    return okResult;
  } else if (totalNum > 7 && totalNum <= 11) {
    return healthyResult;
  } else if (totalNum > 11 && totalNum <= 15) {
    return flourishingResult;
  }
};

export const getTotalCount = (data: IWellbeingQuiz) => {
  let totalNum = 0;
  keys.forEach((key) => (totalNum += data[key] ?? 0));
  return totalNum;
};

/** Returns the key with the highest value.
 *  If there are multiple keys with the highest value, the first will be returned.
 */
export const getStrength = (data: IWellbeingQuiz) => {
  let strength: StepId = "mentallyResilient";
  keys.forEach((key) => {
    const keyVal = data[key] ?? 0;
    const strengthVal = data[strength] ?? 0;

    if (keyVal > strengthVal) {
      strength = key;
    }
  });

  return strength;
};

/**
 * Returns a user friendly string of strengths.
 */
export const getStrengths = (data: IWellbeingQuiz) => {
  let strengths: StepId[] = [];
  let strength: StepId = "mentallyResilient";
  keys.forEach((key) => {
    const keyVal = data[key] ?? 0;
    const strengthVal = data[strength] ?? 0;

    if (keyVal === strengthVal) {
      strengths.push(key);
    }

    if (keyVal > strengthVal) {
      strengths = [key];
      strength = key;
    }
  });

  return strengths
    .map((w) => {
      return getMappedKey(w);
    })
    .join(", ");
};

/** Returns the key with the lowest value.
 *  If there are multiple keys with the lowest value, the first will be returned.
 */
export const getWeakness = (data: IWellbeingQuiz) => {
  let weakness: StepId = "mentallyResilient";
  keys.forEach((key) => {
    const keyVal = data[key] ?? 0;
    const weaknessVal = data[weakness] ?? 0;

    if (keyVal < weaknessVal) {
      weakness = key;
    }
  });

  return weakness;
};

/**
 * Returns a user friendly string of weaknesses.
 */
export const getWeaknesses = (data: IWellbeingQuiz) => {
  let weaknesses: StepId[] = [];
  let weakness: StepId = "mentallyResilient";
  keys.forEach((key) => {
    const keyVal = data[key] ?? 0;
    const weaknessVal = data[weakness] ?? 0;

    if (keyVal === weaknessVal) {
      weaknesses.push(key);
    }

    if (keyVal < weaknessVal) {
      weaknesses = [key];
      weakness = key;
    }
  });

  return weaknesses
    .map((w) => {
      return getMappedKey(w);
    })
    .join(", ");
};
